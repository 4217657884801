import { Component, Input } from '@angular/core';
import { TdcInfo } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-personal-data-account',
  templateUrl: './personal-data-account.component.html',
  styleUrl: './personal-data-account.component.css'
})
export class PersonalDataAccountComponent {

    @Input()
    public userData!: TdcInfo;
    
    @Input()
    public showActionButtons!: boolean;

    @Input()
    public type: 'register' | 'update' = 'update';


    protected resetPassword: boolean = false;


    // METHODS
    public toggleResetPassword(): void {
        this.resetPassword = !this.resetPassword;
    }

}
