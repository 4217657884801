import { Component } from '@angular/core';

@Component({
  selector: 'app-personal-data-protection',
  templateUrl: './personal-data-protection.component.html',
  styleUrl: './personal-data-protection.component.css'
})
export class PersonalDataProtectionComponent {


  public templateVars = {
    text1: `
      <b>Chelsea Fc</b>
      <br>
      Please let us know if you would like to be contacted by Chelsea FC and its group companies with news and promotional 
      information about Chelsea FC, its official sponsors and partners (<a href="www.chelseafc.com/partners" target="_blank">www.chelseafc.com/partners</a>)
      and their products and services where we have the relevant contact details:
    `,
    text2: `
      Please read our Privacy Policy (<a href="www.chelseafc.com/privacy" target="_blank">www.chelseafc.com/privacy</a>) to learn more about Chelsea FC and its group companies, 
      how we use your informationand how to change your mind about receiving marketing from us.
    `,
  }

  public dataMethods = {
    email: false,
    mail: true,
    phone: false,
    sms: false,
  }

}
