<section>

    <ng-container *ngIf="user || type === 'register'">
        <!-- FORM -->
        <app-personal-data-form
            [userData]="userData"
        />
    </ng-container>

    <!-- ACCOUNT SETTINGS -->
    <app-personal-data-account/>

    <!-- DATA PROTECTION -->
    <app-personal-data-protection/>

</section>