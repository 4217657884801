<div class="px-3 lg:py-5 lg:px-0 xl:max-w-6xl mx-auto">
    
    <!-- TITLE -->
    <h4 class="text-secondary text-2xl my-5">
        Personal Data
    </h4>

    <!-- FORM -->
    <form [formGroup]="personalDataForm" class="form">
        
        <!-- PERSONAL DATA -->
        <div class=" grid grid-cols-1 lg:grid-cols-3 grid-rows-2 gap-4">
            
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="firstName">
                    First Name
                </label>
                <input class="input"  type="text" formControlName="firstName" id="firstName" />
            </div>

            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="lastName">
                    Last Name
                </label>
                <input type="text" formControlName="lastName" id="lastName" />
            </div>

            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="gender">
                    Gender
                </label>
                <select name="gender" id="gender" formControlName="gender">
                    <option value="MALE">
                        Male
                    </option>
                    <option value="FEMALE">
                        Female
                    </option>
                    <option value="NOT_LISTED">
                        Not Listed
                    </option>
                    <option value="NOT_ANSWER">
                        Prefer not to answer
                    </option>
                </select>
            </div>

            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="birthDate">
                    Date of Birth
                </label>
                <input type="date" formControlName="birthDate" id="birthDate" />
            </div>

            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="phoneNumber">
                    Primary Phone Number
                </label>
                <input type="text" formControlName="phoneNumber" id="phoneNumber" />
            </div>
        </div>
       
        <!-- ADRESS DATA -->
        <div class="grid grid-cols-1 lg:grid-cols-3 grid-rows-2 gap-4 mt-5">
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="address">
                    Address Line 1
                </label>    
                <input type="text" formControlName="address" id="address" />
            </div>
            
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="address2">
                    Address Line 2
                </label>
                <input type="text" formControlName="address2" id="address2" />
            </div>
            
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="zipCode">
                    Postal Code (ZIP)
                </label>
                <input type="text" formControlName="zipCode" id="zipCode" />
            </div>
    
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="country">
                    Country
                </label>
                <select name="" id=""></select>
            </div>
            
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="state">
                    State / Province
                </label>
                <input type="text" formControlName="state" id="state"/>
            </div>
    
            <div class="flex flex-col">
                <label class="text-main font-semibold font-main mb-1" for="city">
                    City
                </label>
                <input type="text" formControlName="city" id="city"/>

            </div>
    
        </div>

    </form>

    <!-- ACTION BUTTONS -->
    <div class="flex justify-between my-5">
        
        <div class="w-1/6 lg:w-2/12">
            <button class="button">
                Edit
            </button>
        </div>

        <div class="flex w-4/6 lg:w-4/12 gap-x-3">
            <button class="button">
                Dismiss
            </button>
            <button class="button">
                Save
            </button>
        </div>

    </div>

</div>
