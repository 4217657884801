import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TdcInfo } from 'src/app/shared/models/user.model';
import { PersonalDataForm } from './Personal-data.modal';
import * as countries from 'src/assets/data/countries-code.json'
import { CountryDataModel } from 'src/app/shared/models/countries.model';


@Component({
  selector: 'app-personal-data-form',
  templateUrl: './personal-data-form.component.html',
  styleUrl: './personal-data-form.component.css'
})
export class PersonalDataFormComponent {
  
  @Input()
  public userData!: TdcInfo | undefined;
  
  @Input()
  public showActionButtons!: boolean;

  @Input()
  public editMode!:  boolean;

  private countries: any = countries;

  // FORM VARS
  public personalDataForm: FormGroup<PersonalDataForm> = new FormGroup<PersonalDataForm>({
    
    firstName:    new FormControl<string>('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
    lastName:     new FormControl<string>('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
    gender:       new FormControl<string>('', [Validators.required]),
    birthDate:    new FormControl<Date>(new Date(), [Validators.required, Validators.max(parseInt(new Date().toDateString()))]),
    phoneNumber:  new FormControl<string>('', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]),
    address:      new FormControl<string>('', [Validators.required]),
    address2:     new FormControl<string>(''),
    zipCode:      new FormControl<string>('', [Validators.required, Validators.pattern(/^[0-9]{5}$/)]),
    country:      new FormControl<string>('', [Validators.required]),
    state:        new FormControl<string>('', [Validators.required]),
    city:         new FormControl<string>('', [Validators.required]),

  })

  ngOnInit(): void {
    this.initComponent();
  }

  public setUserData(userData: TdcInfo): void {

    // Personal Data
    this.personalDataForm.controls.firstName.setValue(userData.first_name);
    this.personalDataForm.controls.lastName.setValue(userData.last_name);
    this.personalDataForm.controls.gender.setValue(userData.gender);
    this.personalDataForm.controls.birthDate.setValue(userData.birthday);
    this.personalDataForm.controls.phoneNumber.setValue(userData.phone.number);

    // Address Data
    this.personalDataForm.controls.address.setValue(userData.address.address1);
    this.personalDataForm.controls.zipCode.setValue(userData.address.postal_code);
    this.personalDataForm.controls.country.setValue(userData.address.address_country_code);
    this.personalDataForm.controls.state.setValue(userData.address.address_country_code);
    this.personalDataForm.controls.city.setValue(userData.address.city);

    // Optional Address Data
    if(userData.address.address2){
      this.personalDataForm.controls.address2.setValue(userData.address.address2);
    }

  }

  private initComponent(): void {
    if(this.userData){
      this.editMode = false;
      this.setUserData(this.userData);
    }else{
      this.editMode = true;
    }
  }
  
}
