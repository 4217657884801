<div class="px-3 lg:py-5 lg:px-0 xl:max-w-6xl mx-auto">

    <!-- TITLE -->
    <h4 class="text-secondary text-2xl my-5 xl:max-w-6xl mx-auto ">
        Data Protection
    </h4>

    <!-- CONTENT -->
    <div>
        <!-- TEXT 1 -->
        <p class="text-main" [innerHTML]="templateVars.text1"></p>

        <!-- FORM -->
        <form action="" class="my-5 grid grid-cols-2 gap-y-2 align-middle md:flex md:max-w-xl md:justify-between">

            <div class="flex flex-row-reverse justify-center" *ngFor="let contactMethods of dataMethods | keyvalue ">
                
                <label [for]="'data_'+ contactMethods.key" class="ml-3 w-2/4">
                    {{contactMethods.key | titlecase}}
                </label>
                <input type="checkbox" class="radio" [name]="'data_'+ contactMethods.key" [id]="'data_'+ contactMethods.key" [value]="contactMethods.value">

            </div>
            
        </form>

        <!-- TEXT 2 -->
        <p class="text-main" [innerHTML]="templateVars.text2"></p>
        
    </div>

    <!-- ACTION BUTTONS -->
    <div class="flex justify-between my-5">
        
        <div class="w-1/6 lg:w-2/12">
            <button class="button">
                Edit
            </button>
        </div>

        <div class="flex w-4/6 lg:w-4/12 gap-x-3">
            <button class="button">
                Dismiss
            </button>
            <button class="button">
                Save
            </button>
        </div>

    </div>
</div>