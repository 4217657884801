<div class="px-3 py-3 lg:py-5 lg:px-0 bg-main/5">

    <!-- TITLE -->
    <h4 class="text-secondary text-2xl my-5 xl:max-w-6xl mx-auto ">
        Account Settings
    </h4>

    <!-- EMAIL AND PASSWORD RESET -->
    <div class="xl:max-w-6xl mx-auto ">

        <!-- EMAIL -->
        <ng-container *ngIf="!resetPassword else passwordReset">

            <div class="flex md:items-end gap-x-2 lg:gap-x-4 md:mb-16">
               
                <form class="form flex flex-col w-full mx-2 md:mx-0 pb-5 md:pb-0 md:max-w-sm" action="">
                    
                    <label class="text-main font-semibold font-main mb-1" for="email">
                        Account Email
                    </label>
                    <input type="email" formControlName="email" id="email" />

                    <span (click)="toggleResetPassword()" role="button" class="block ml-auto font-main font-semibold text-xs my-2 text-main transition-transform hover:scale-95 md:hidden">
                        Password Reset
                    </span>

                </form>

                <button (click)="toggleResetPassword()" class="button hidden md:block w-1/5 h-3/4">
                    Password Reset
                </button>
            </div>

        </ng-container>

        <!-- PASSWORD UPDATE -->
        <ng-template #passwordReset>
            
            <div class="flex md:items-end gap-x-2 lg:gap-x-4 md:mb-16 md:max-w-5xl">
               
                <form class="form flex flex-col md:flex-row w-full mx-2 pb-5 gap-y-4 md:mx-0 md:pb-0 md:gap-3 md:w-2/3" action="">
                    
                    <div class="flex flex-col md:w-1/2">
                        <label class="text-main font-semibold font-main mb-1" for="password">
                            New Password
                        </label>
                        <input type="password" formControlName="password" id="password" />
                    </div>

                    <div class="flex flex-col md:w-1/2">
                        <label class="text-main font-semibold font-main mb-1" for="password_2">
                            Re Type Password
                        </label>
                        <input type="password" formControlName="password_2" id="password_2" />
                        <span (click)="toggleResetPassword()" role="button" class="block ml-auto font-main font-semibold text-xs my-2 text-main transition-transform hover:scale-95 md:hidden">
                            Go Back
                        </span>
                    </div>
                    
                </form>

                <button (click)="toggleResetPassword()" class="button hidden md:block w-1/6 h-3/4">
                    Back
                </button>
            </div>

        </ng-template>

        <!-- NEW PASSWORD -->
        <ng-container *ngIf="type === 'register'" >
            <div class="flex md:items-end gap-x-2 lg:gap-x-4 md:mb-16 md:max-w-5xl">
               
                <form class="form flex flex-col md:flex-row w-full mx-2 pb-5 gap-y-4 md:mx-0 md:pb-0 md:gap-3 md:w-2/3" action="">
                    
                    <div class="flex flex-col md:w-1/2">
                        <label class="text-main font-semibold font-main mb-1" for="password">
                            Password
                        </label>
                        <input type="password" formControlName="password" id="password" />
                    </div>

                    <div class="flex flex-col md:w-1/2">
                        <label class="text-main font-semibold font-main mb-1" for="password_2">
                            Re Type Password
                        </label>
                        <input type="password" formControlName="password_2" id="password_2" />
                    </div>
                    
                </form>
            </div>
        </ng-container>

        <!-- ACTION BUTTONS -->
        <ng-container>
            <div class="flex justify-between my-5">
        
                <div class="w-1/6 lg:w-2/12">
                    <button class="button">
                        Edit
                    </button>
                </div>
        
                <div class="flex w-4/6 lg:w-4/12 gap-x-3">
                    <button class="button">
                        Dismiss
                    </button>
                    <button class="button">
                        Save
                    </button>
                </div>
        
            </div>
        </ng-container>

    </div>
</div>