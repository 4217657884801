import { Component, inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { TdcInfo, User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrl: './personal-data.component.css'
})
export class PersonalDataComponent implements OnInit {

  private auth: AuthService = inject(AuthService);
  
  protected user!: User | undefined;
  protected type!: 'register' | 'update';

  ngOnInit(): void {
    this.initComponent();
  }

  public get userData(): TdcInfo | undefined {
    if(this.user){
      return this.user.tdc_info;
    }else {
      return;
    }
  }

  private initComponent(): void {
    this.auth.getUser().then((response: User|false) =>{
      if(response){
        this.user = response;
        this.type = 'update';
      }else{
        this.type = 'register'
      }
    });
  }

}
